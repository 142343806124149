.label_group {

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  * {
    margin: .5rem;
  }
}
