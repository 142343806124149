@import "./src/scss/variables";

:global(#root) {

  .mb_link {
    color: $black
  }


  .menu {
    background: #60C5AC;
    display: grid;
    grid-template-columns: 20px auto 20px;
    grid-template-rows: 80px;
    margin: 0;
    border: none;

    &::before {
      background-color: transparent;
    }

    border-radius: 0px;
  }

  .logo {
    justify-self: center;
    padding: 0 1rem;

    &::before {
      background-color: transparent;
    }

    .wordmark {
      color: black;
      font-size: 1.8rem;
    }
  }

  .burger {
    justify-self: left;

    transform: scale(0.7);
    padding: 0;

    &::before {
      background-color: transparent;
    }
  }

  .pushable {
    margin: 0;
  }

  .pusher {
    min-height: 90vh;
  }
}

