@import 'src/scss/variables';
:global(#root) {

  .label_group {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    * {
      margin: .5rem;
    }
  }

  .sub_menu {
    justify-content: center;
    border: none;
    border-radius: 0;
  }

  .bottom_row {
    .sub_nav {
      padding: 0 3rem;
      justify-self: center;
      font-weight: bold;

      &::before {
        background-color: transparent;
      }

      &:hover {
        background-color: transparent;
      }

      span {
        border-bottom: transparent .2rem solid;
        &:hover{
          border-bottom: #000 .2rem solid;
        }

        &.selected {
          border-bottom: #000 .2rem solid;
        }
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    height: 100px;

    .excerpt {
      flex-grow:  2;
      background: $black;
      color: $white
    }

    .text {
      flex-grow: 1;
      background: $light_gray;
      color: $gray;

    }
  }
}
